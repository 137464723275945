import React, { useEffect, useState } from 'react';
import {
  ArrowDown,
  ArrowUp,
  Search,
  SlashCircle,
  XCircle,
} from 'react-bootstrap-icons';
import ReactPaginate from 'react-paginate';
import Loader from './loader';
import RangePicker from 'react-range-picker';
import moment from 'moment';

function Table(props) {
  let [searchValue, setSearchValue] = useState('');
  let [$skip, setSkip] = useState(0);
  let [$limit, setLimit] = useState(10);
  let [total, setTotal] = useState(10);
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  let [filter, setFilter] = useState({});
  let [columns, setColumns] = useState(props.columns);

  // sorting
  let [sort, setSort] = useState(props.sort);
  let [sortDirection, setSortDirection] = useState(props.sortDirection);

  useEffect(() => {
    fetchData();
  }, [$skip, $limit, filter]);

  let fetchData = async () => {
    setLoading(true);

    let params = {
      $skip: $skip,
      $limit,
      ...filter,
    };

    // console.log(params, $skip);

    if (sort) {
      // console.log('sort', sort);
      params.$sort = {};
      params.$sort[sort] = sortDirection;
    }

    if (props.search) {
      // $or[0][archived][$ne]=true
      let search = searchValue.replace(/\s/, '+');

      let $or = [];
      props.search.map((d, i1) => {
        let s = {};
        s[d] = { $like: `%${search}%` };
        $or.push(s);
      });
      params.$or = $or;
    }

    // console.log('params', params);

    await props.fetch(params);
    // setData(data.data);
    // setLimit(data.limit);
    // setSkip(data.skip);
  };

  useEffect(() => {
    // console.log(props.data);
    if (props.data) {
      setData(props.data.data);

      // setLimit(props.data.limit);
      // setSkip(props.data.skip);
      setTotal(props.data.total);
    }
  }, [props.data]);

  let handlePageClick = (event) => {
    const newOffset = event.selected * $limit;

    setSkip(newOffset);
  };

  useEffect(() => {
    setLoading(false);
  }, [data]);

  // useEffect(() => {
  //   setData(props.data.data);
  //   setSkip(props.data.skip);
  //   setLimit(props.data.limit);
  // }, [props.data]);

  useEffect(() => {
    fetchData();
  }, []);

  let getTableHeaders = () => {
    let titles = [];
    if (data.length) {
      let entry = data[0];
      if (props.entry) {
        entry = props.entry(entry);
      }

      titles = Object.keys(entry);
      // remove onClick from titles
      titles = titles.filter((d) => d !== 'onClick');
    }

    // console.log(titles);
    return titles;
  };

  const placeholder = ({ startDate, endDate }) => {
    if (!startDate) {
      return (
        <div className='form-control form-control-sm'> Select Date Range </div>
      );
    }
    return (
      <div className='placeholderWrap form-control form-control-sm'>
        <div className='cursor-pointer'>
          {moment(startDate).format('DD/MM/YYYY')}{' '}
          {endDate && <>- {moment(endDate).format('DD/MM/YYYY')}</>}{' '}
          &nbsp;&nbsp;
          <XCircle
            size={14}
            onClick={() => {
              let $filter = { ...filter };

              $filter[props.calendar] = {};

              setFilter($filter);
            }}></XCircle>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={'d-flex flex-fill flex-column blue-table'}>
        <div
          className='d-flex flex-row justify-content-between align-items-center '
          style={{ zIndex: 1000 }}>
          <div className='d-flex flex-row align-items-center '>
            {props.filter && (
              <div className='table-filter'>
                {props.search && (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      fetchData();
                    }}
                    className='d-flex flex-column justify-content-center position-relative'>
                    <Search
                      className='search-icon cursor-pointer '
                      color='grey'
                      onClick={props.fetchData}
                    />
                    <input
                      type='search'
                      className='form-control form-control-sm pl-5 rounded bg-light'
                      value={searchValue}
                      placeholder='Search'
                      onChange={(e) => {
                        console.log({ searchValue: e.target.value });
                        setSearchValue({ searchValue: e.target.value });
                      }}
                    />
                  </form>
                )}

                {props.filter.map((d, i) => (
                  <div className='mx-2  ' key={i}>
                    <label className='form-label'>
                      <small className='fw-bold'>{d.label}</small>
                    </label>

                    <select
                      className='form-select form-select-sm'
                      aria-label='Default select example'
                      onChange={(e) => {
                        let $filter = { ...filter };
                        $filter[d.filterKey] = parseFloat(e.target.value);
                        setFilter($filter);
                      }}
                      defaultValue={d.defaultValue}>
                      <option selected>None</option>
                      {d.options.map((opt, i) => (
                        <option value={opt.value} key={i}>
                          {opt.key}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            )}

            {props.calendar && (
              <div>
                <label className='form-label'>
                  <small className='fw-bold'>Date Range</small>
                </label>
                <div>
                  <RangePicker
                    placeholder={placeholder}
                    // selectTime
                    onDateSelected={(f, l) => {
                      let $filter = { ...filter };

                      $filter[props.calendar] = {
                        $gte: f,
                        $lte: l,
                      };

                      setFilter($filter);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='d-flex flex-row align-items-center entries-counter'>
            {/* {csv === true && (
            <button
              type='button'
              onClick={() => {
                this.setState({ CSVmodal: true });
              }}
              className='option-card pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-outline-primary btn-round btn-sm mr-3'>
              <Download size={16} />
              <span className='pl-2 font-weight-bold no-wrap'>
                Generate CSV
              </span>
            </button>
          )} */}

            <span className='me-2'>Show </span>
            <select
              className='form-control form-control-sm'
              onChange={(event) => {
                setLimit(parseInt(event.target.value));
                fetchData();
              }}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span className='ms-2'> entries </span>
          </div>
        </div>

        <div className='d-flex flex-fill tb-cover position-relative'>
          {loading && (
            <div className='d-flex flex-fill flex-row align-items-center justify-content-center '>
              <Loader loading={loading}></Loader>
            </div>
          )}

          {!loading && !data.length && (
            <div className='w-100 h-100 empty-holder bg-light d-flex flex-row align-items-center justify-content-center'>
              <div className='text-muted d-flex flex-column align-items-center'>
                <SlashCircle size={30} />
                <span className='mt-3'>No Data</span>
              </div>
            </div>
          )}

          {!loading && (
            <div className='table-container w-100'>
              <table className='table text-dark table-hover'>
                <thead>
                  <tr>
                    {/* {this.state.checkbox && (
                    <th className=''>
                      <input
                        type='checkbox'
                        className='styled-checkbox'
                        name=''
                        id='index'
                        checked={this.state.checkboxIDS.every((val) =>
                          this.state.checked.includes(val)
                        )}
                        onChange={this.checkAll}
                      />
                      <label for='index'></label>
                    </th>
                  )} */}

                    {data.length > 0 &&
                      columns.map((title, i) => {
                        return (
                          <th
                            key={i}
                            className={
                              'text-capitalize ' +
                              (props.sortable &&
                              props.sortable.includes(title.key)
                                ? 'cursor-pointer'
                                : '')
                            }
                            onClick={() => {
                              if (!props.sortable.includes(title.key)) return;
                              setSort(title.key);
                              setSortDirection(sortDirection === -1 ? 1 : -1);
                              fetchData();
                            }}>
                            {/* add sort direction icon */}
                            {sort === title.key && (
                              <span className='me-2'>
                                {sortDirection === 1 ? (
                                  <ArrowUp size={16} />
                                ) : (
                                  <ArrowDown size={16} />
                                )}
                              </span>
                            )}

                            {title.label}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((rawRow, index) => {
                    let row = rawRow;
                    if (props.entry) {
                      row = props.entry(row);
                    }

                    return (
                      <tr
                        key={index}
                        className='cursor-pointer'
                        onClick={row.onClick}>
                        {columns.map((column, i) => {
                          if (column.key === 'onClick') return null;

                          if (column.dynamic && !row[column.key]) {
                            return null;
                          }

                          return (
                            <td
                              key={i}
                              className='table-data'
                              colSpan={column.span}>
                              {row[column.key] || '-'}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className='d-flex flex-row align-items-center justify-content-between'>
          {!props.hidePagination && (
            <ReactPaginate
              nextLabel='next >'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={Math.ceil(total / $limit)}
              previousLabel='< previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          )}
          <div className='title'>{total} Total Entries</div>
        </div>
      </div>
    </>
  );
}

export default Table;
