import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../components/system/modal';
import { XCircle } from 'react-bootstrap-icons';
import Nav from '../components/system/nav';

class AccessManagement extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    showCreateModal: false,
    inputs: {},
    showPermissionModal: false,
    permissionInputs: {},

    groups: [],
    activeGroup: null,
    categories: [],
    permisions: {},
    permissionTypes: {},
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav title='Access Management'></Nav>

        <div className='row px-3 pb-5'>
          <div className='col-md-3'>
            <div className='mt-3 border-0 card user-group-cards  shadow p-3'>
              <h5 className='m-0 font-weight-bod'>User Groups</h5>
              <hr />

              <div class='list-group list-group-flush'>
                {this.state.activeGroup &&
                  this.state.groups.map((d, i) => (
                    <div
                      class={
                        'list-group-item list-group-item-action ' +
                        (this.state.activeGroup === d.id && 'active')
                      }
                      key={i}
                      onClick={() => {
                        this.setState({ activeGroup: d.id });
                      }}>
                      {d.name}
                    </div>
                  ))}
              </div>

              <button
                className='btn btn-outline-primary btn-round btn-sm mt-4'
                onClick={() => {
                  this.setState({
                    showCreateModal: true,
                    inputs: { name: '' },
                  });
                }}>
                Add group
              </button>
            </div>
          </div>

          <div className='col-md-8'>
            <div className='mt-3 border-0 card shado shadow'>
              <div className='p-3'>
                <h5 className='m-0 font-weight-bol'>Permissions</h5>
                <hr />

                {this.state.categories.map((d, i) => (
                  <div className='card mb-3' key={i}>
                    <div className='card-header d-flex flex-row align-items-center justify-content-between'>
                      <div className='m-0 text-capitalize'>
                        {d.replace(/-/g, ' ')}
                      </div>
                      <button
                        className='btn btn-primary btn-sm  justify-content-between'
                        onClick={() => {
                          this.setState({
                            permissionInputs: { category: d, name: '' },
                            showPermissionModal: true,
                          });
                        }}>
                        Add permission
                      </button>
                    </div>

                    <div className='card-content '>
                      <div className='row px-3 py-3'>
                        {this.state.permissionTypes[d] &&
                          this.state.permissionTypes[d].map((d1, i) => (
                            <div className='col-md-4' key={i}>
                              <div class='form-group form-check'>
                                <input
                                  type='checkbox'
                                  class='form-check-input'
                                  id={d + d1.id}
                                  checked={
                                    this.state.permisions[
                                      this.state.activeGroup
                                    ] &&
                                    this.state.permisions[
                                      this.state.activeGroup
                                    ][d1.id]
                                  }
                                  onChange={() => {
                                    if (
                                      this.state.permisions[
                                        this.state.activeGroup
                                      ] &&
                                      this.state.permisions[
                                        this.state.activeGroup
                                      ][d1.id]
                                    ) {
                                      // remove permission
                                      this.removePermission(
                                        this.state.permisions[
                                          this.state.activeGroup
                                        ][d1.id].id
                                      );
                                    } else {
                                      // add permission
                                      this.createPermission({
                                        userGroupId: this.state.activeGroup,
                                        permissionTypeId: d1.id,
                                      });
                                    }
                                  }}
                                />
                                <label class='form-check-label' for={d + d1.id}>
                                  {d1.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <Modal
          isActive={this.state.showCreateModal}
          close={() => {
            this.setState({ showCreateModal: false });
          }}
          title='Create A Group'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.createGroup();
            }}>
            <div>
              <label className='font-weight-bold'>Group Name</label>
              <input
                type='text'
                required={true}
                placeholder='...'
                className='form-control'
                value={this.state.inputs.name}
                onChange={(e) => {
                  let { inputs } = this.state;
                  inputs.name = e.target.value;
                  this.setState({ inputs });
                }}
              />
            </div>
            <div className='mt-4 text-center'>
              <button type='submit' className='btn btn-outline-primary px-4'>
                Submit
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isActive={this.state.showPermissionModal}
          close={() => {
            this.setState({ showPermissionModal: false });
          }}
          title='Add a permission'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.createPermissionType();
            }}>
            <div className='mb-4'>
              <label className='font-weight-bol'> Category</label>
              <input
                type='text'
                value={this.state.permissionInputs.category}
                required={false}
                className='form-control'
                readOnly={true}
                disabled={true}
              />
            </div>

            <div>
              <label className='font-weight-bol'> Name</label>
              <input
                type='text'
                required={true}
                placeholder='...'
                className='form-control'
                value={this.state.permissionInputs.name}
                onChange={(e) => {
                  let { permissionInputs } = this.state;
                  permissionInputs.name = e.target.value;
                  this.setState({ permissionInputs });
                }}
              />
            </div>
            <div className='mt-5 text-center'>
              <button type='submit' className='btn btn-outline-primary px-4'>
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    this.fetchGroups();
    this.fetchPermissionTypes();
    this.fetchPermissions();
  }

  fetchGroups = () => {
    window.app
      .service('user-groups')
      .find({
        query: {
          $limit: 100,
        },
      })
      .then((response) => {
        this.setState({
          groups: response.data,
          activeGroup: response.data[0] && response.data[0].id,
        });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  fetchPermissions = () => {
    this.setState({ table_loading: true });

    window.app
      .service('permissions')
      .find({
        query: {
          $limit: 250,
        },
      })
      .then((response) => {
        let permisions = {};

        response.data.map((d) => {
          if (!permisions[d.userGroupId]) permisions[d.userGroupId] = {};
          permisions[d.userGroupId][d.permissionTypeId] = d;
        });

        this.setState({ permisions });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  fetchPermissionTypes = () => {
    this.setState({ table_loading: true });

    window.app
      .service('permission-types')
      .find({
        query: {
          $limit: 100,
          $sort: {
            name: 1,
          },
        },
      })
      .then((response) => {
        let permissionTypes = {};

        response.data.map((d) => {
          if (!permissionTypes[d.category]) permissionTypes[d.category] = [];
          permissionTypes[d.category].push(d);
        });

        // console.log(JSON.stringify(response.services.sort()));

        this.setState({
          permissionTypes,
          categories: [
            'users',
            // "sms",
            'leads',
            'access-management',
          ],
        });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  // create

  createGroup = () => {
    this.setState({ showCreateModal: false });

    window.app
      .service('user-groups')
      .create(this.state.inputs)
      .then((response) => {
        console.log(response);
        this.fetchGroups();
        window.alert2.notify({
          title: 'Group Added Successfully',
        });
      })
      .catch((err) => {
        console.log(err);

        window.alert2.show({
          icon: <XCircle size={60}></XCircle>,
          loader: false,
          title: 'Error Creating group',
          message: err.message,
          buttons: true,
        });
      });
  };

  createPermissionType = () => {
    this.setState({ showPermissionModal: false });

    window.app
      .service('permission-types')
      .create(this.state.permissionInputs)
      .then((response) => {
        // console.log(response);
        this.fetchPermissionTypes();
        window.alert2.notify({
          title: 'Permission Added Successfully',
        });
      })
      .catch((err) => {
        console.log(err);

        window.alert2.show({
          icon: <XCircle size={60}></XCircle>,
          loader: false,
          title: 'Error Creating group',
          message: err.message,
          buttons: true,
        });
      });
  };

  createPermission = (data) => {
    // return;
    window.app
      .service('permissions')
      .create(data)
      .then((response) => {
        this.fetchPermissions();
      })
      .catch((err) => {
        console.log(err);

        window.alert2.show({
          icon: <XCircle size={60}></XCircle>,
          loader: false,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };

  // remove

  removePermission = (id) => {
    window.app
      .service('permissions')
      .remove(id)
      .then((response) => {
        this.fetchPermissions();
      })
      .catch((err) => {
        console.log(err);

        window.alert2.show({
          icon: <XCircle size={60}></XCircle>,
          loader: false,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };
}

export default AccessManagement;
