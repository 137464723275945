import { Button } from 'bootstrap';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AssignLeadOwnership from '../components/assignLeadOwnership';
import moment from 'moment';
import Access from '../components/system/accessManager';

let allowUpdates = false;

function LeadsProfile() {
  let [leadData, setLeadData] = useState({});
  let [options, setOptions] = useState([]);
  let [showAssignOwnershipModal, setShowAssignOwnershipModal] = useState(false);
  let [statusHistory, setStatusHistory] = useState([]);
  let [assignmentHistory, setAssignmentHistory] = useState([]);

  let params = useParams();

  let fetchLeadData = async () => {
    await window.app
      .service('leads')
      .get(params.id)
      .then((data) => {
        console.log(data);
        setLeadData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let updateLeadData = async () => {
    console.log(params.id);
    await window.app
      .service('leads')
      .patch(params.id, leadData)
      .then((data) => {
        // setLeadData(data);
        fetchLeadData();
        console.log(data);
        window.notify('Lead Updated', 'success');

        fetchStatusHistory();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLeadData();
    fetchStatusHistory();

    setOptions(
      Object.keys(window.lead_status).map((d) => ({
        key: window.lead_status[d],
        value: d,
      }))
    );
  }, []);

  // only update lead data when status or user changes. prevent update when lead data is fetched initially
  useEffect(() => {
    if (allowUpdates) {
      updateLeadData();
    }
    allowUpdates = false;
  }, [leadData.status, leadData.userId]);

  // fetch status history
  let fetchStatusHistory = async () => {
    fetchAssignmentHistory();
    window.app
      .service('logs')
      .find({
        query: {
          $limit: 250,
          $skip: 0,
          entityId: params.id,
          entityName: 'leads',
          $sort: { createdAt: -1 },
          'metadata.data.action': 'status_update',
        },
      })
      .then((response) => {
        setStatusHistory(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchAssignmentHistory = async () => {
    window.app
      .service('logs')
      .find({
        query: {
          $limit: 250,
          $skip: 0,
          entityId: params.id,
          entityName: 'leads',
          $sort: { createdAt: -1 },
          'metadata.data.action': 'assign_ownership',
        },
      })
      .then((response) => {
        setAssignmentHistory(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      {leadData.data ? (
        <div>
          <div className='card m-3'>
            <div className='row align-items-center'>
              <div className='col-md-3 d-flex flex-row justify-content-center'>
                <div className='member-icon'>
                  <div className='avatar'>{leadData.data.first_name[0]}</div>
                </div>
              </div>
              <div className='col-md-5 py-4'>
                <h4 className='fw-bold'>
                  {leadData.data.first_name} {leadData.data.last_name}
                </h4>

                <table>
                  <tr>
                    <td>
                      <small className='text-muted'>Email</small>
                    </td>
                    <td>: {leadData.data.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <small className='text-muted'>Phone</small>
                    </td>
                    <td>: {leadData.data.contact_number}</td>
                  </tr>
                  <tr>
                    <td>
                      <small className='text-muted'>Product </small>
                    </td>
                    <td>
                      : {window.products[leadData.data.product_of_interest]}
                    </td>
                  </tr>
                </table>
              </div>

              <div className='col-md-3 py-4 '>
                {/* dropdown that shows status and allows user to update */}

                <Access permission='leads_UPDATE_STATUS'>
                  <div className='form-group'>
                    <label htmlFor='status'>
                      <small>Status</small>
                    </label>
                    <select
                      className='form-control'
                      id='status'
                      defaultValue={leadData.status}
                      onChange={(e) => {
                        allowUpdates = true;
                        setLeadData({
                          ...leadData,
                          status: e.target.value,
                          action: 'status_update',
                        });
                      }}>
                      {/* <option value='new'>New</option> */}
                      {options.map((d) => (
                        <option key={d.value} value={d.value}>
                          {d.key}
                        </option>
                      ))}
                    </select>
                  </div>
                </Access>

                <Access permission='leads_ASSIGN_USER'>
                  <div className='form-group mt-3'>
                    {/* change ownership of lead */}
                    <label htmlFor='owner'>
                      <small className='text-muted'> Assigned To</small>
                    </label>
                    <div>
                      <button
                        className='btn btn-outline-primary btn text-start text-capitalize w-100'
                        onClick={() => setShowAssignOwnershipModal(true)}>
                        {leadData.user
                          ? leadData.user.firstname +
                            ' ' +
                            leadData.user.lastname
                          : 'Assign Ownership'}
                      </button>
                    </div>
                  </div>
                </Access>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center py-5'>
          <span>Loading ...</span>
        </div>
      )}

      <div className='row m-3'>
        <Access permission='leads_READ_ASSIGN_HISTORY'>
          <div className='col-md-6'>
            <p className='text-uppercase my-3 fw-bold'>
              <small>Status Change History</small>
            </p>

            <div className='card p-2 '>
              <table className='table table-striped m-0'>
                <thead>
                  <tr>
                    <th scope='col'>Date</th>
                    <th scope='col'>Status</th>
                    <th scope='col'>Updated By</th>
                  </tr>
                </thead>
                <tbody>
                  {statusHistory.map((d) => (
                    <tr>
                      <td>{moment(d.createdAt).calendar()}</td>
                      <td>{window.lead_status[d.metadata.result.status]}</td>
                      <td>
                        {d.user.firstname} {d.user.lastname}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Access>

        <Access permission='leads_READ_STATUS_HISTORY'>
          <div className='col-md-6'>
            <p className='text-uppercase my-3 fw-bold'>
              <small>Assignment Change History</small>
            </p>

            <div className='card p-2 '>
              <table className='table table-striped m-0'>
                <thead>
                  <tr>
                    <th scope='col'>Date</th>
                    <th scope='col'>Asigneee</th>
                    <th scope='col'>Updated by</th>
                  </tr>
                </thead>
                <tbody>
                  {assignmentHistory.map((d) => (
                    <tr>
                      <td>{moment(d.createdAt).calendar()}</td>
                      <td>
                        {d.metadata.result.user.firstname +
                          ' ' +
                          d.metadata.result.user.lastname}
                      </td>
                      <td>
                        {d.user.firstname} {d.user.lastname}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Access>
      </div>

      <AssignLeadOwnership
        isActive={showAssignOwnershipModal}
        userId={params.userId}
        leadId={params.id}
        onSubmit={(userId) => {
          allowUpdates = true;
          setLeadData({ ...leadData, userId, action: 'assign_ownership' });
        }}
        close={() => setShowAssignOwnershipModal(false)}
      />
    </div>
  );
}

export default LeadsProfile;
