import React, { Component } from 'react';
import { ShieldX } from 'react-bootstrap-icons';

function Access({ children, message, page, permission }) {
  let authorized =
    permission && permission !== 'all' ? window.permissions[permission] : true;

  // console.log("authorized", authorized);

  return authorized ? (
    <>{children}</>
  ) : message || page ? (
    <div className='text-center'>
      <div className='py-4 text-center m-5 card d-inline-block p-5 col-4'>
        <ShieldX size={60}></ShieldX>
        <div className='mt-3'>ACCESS FORBIDDEN</div>
      </div>
    </div>
  ) : (
    <></>
  );
}

let hasPermission = (permission) => {
  if (!window.permissions) {
    return true;
  }
  if (permission === 'all') {
    return true;
  }
  return window.permissions[permission];
};

export default Access;
export { hasPermission };
