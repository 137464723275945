import React from 'react';
import { Link } from 'react-router-dom';
import Access from './accessManager';

function Nav({ title, links = [] }) {
  return (
    <nav class='navbar py-3 px-4 custom-navbar'>
      <h2 className='navbar-brand m-0'>{title}</h2>

      <div
        class='navbar-toggler'
        type='button'
        aria-controls='navbarNavDropdown'
        aria-expanded='false'
        aria-label='Toggle navigation'>
        <span class='navbar-toggler-icon'></span>
      </div>

      <div class='nav-links'>
        <ul class='navbar-nav d-flex flex-row'>
          {links.map((d, i) => (
            <Access permission={d.permission}>
              <li class='nav-item' key={i}>
                <button
                  to={d.link}
                  key={i}
                  onClick={() => {
                    if (d.onClick) {
                      d.onClick();
                    }
                  }}
                  className={
                    'mx-2 btn rounded btn-round font-weight-bold px-4 ' +
                    (d.className || 'btn-primary')
                  }>
                  {d.text}
                </button>
              </li>
            </Access>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
