import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Form from '../components/system/form';
import Input from '../components/system/input';
import Modal from '../components/system/modal';
import Nav from '../components/system/nav';
import Table from '../components/system/table';
import Access from '../components/system/accessManager';

function Users() {
  let [data, setData] = useState();
  let [createModal, showCreateModal] = useState(false);
  let [editModal, showEditModal] = useState(false);
  let [inputs, setInputs] = useState({ status: 1 });

  let [user, setUser] = useState({});

  let [userTypes, setUserTypes] = useState([]);

  let createUser = () => {
    console.log('creating user');
    console.log(inputs);
    window.app
      .service('users')
      .create(inputs)
      .then((response) => {
        window.notify('User created successfully', 'success');

        showCreateModal(false);

        console.log(response);
        fetchData();
        return response;
      })
      .catch((err) => {
        window.notify('Error creating user : ' + err.message + '', 'error');
        console.error(err);
      });
  };

  let updateUser = () => {
    window.app
      .service('users')
      .patch(user.id, user)
      .then((response) => {
        window.notify('User updated successfully', 'success');

        showEditModal(false);

        fetchData();
        return response;
      })
      .catch((err) => {
        console.error(err);
        window.notify('Error updating user', 'error');
      });
  };

  let fetchData = async (query) => {
    console.log('query', query);
    return await window.app
      .service('users')
      .find({
        query: { ...query },
      })
      .then((response) => {
        console.log(response);
        setData(response);
      })
      .catch((err) => {
        // this.setState({ table_loading: false });
        console.error(err);
      });
  };

  let fetchUserTypes = async () => {
    return await window.app
      .service('user-groups')
      .find({
        query: {
          $limit: 250,
          $skip: 0,
        },
      })
      .then((response) => {
        let types = [];
        response.data.map((d) => {
          types.push({ label: d.name, value: d.id });
        });
        setUserTypes(types);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchUserTypes();
  }, []);

  return (
    <div className=''>
      <Nav
        title={'Users'}
        links={[
          {
            text: 'Add a User',
            onClick: () => showCreateModal(true),
            permission: 'users_CREATE',
          },
        ]}></Nav>

      <div className='card page-card p-3  mx-3 shadow-sm'>
        <Table
          data={data}
          fetch={fetchData}
          columns={[
            {
              label: 'Name',
              key: 'name',
            },
            {
              label: 'Email',
              key: 'email',
            },
            // {
            //   label: 'User Type',
            //   key: 'type',
            // },
            {
              label: 'Created',
              key: 'created',
            },
            {
              label: '',
              key: '',
            },
          ]}
          entry={(data) => ({
            name: `${data.firstname} ${data.lastname}`,
            email: data.email,
            type: data.type,
            created: moment(data.createdAt).calendar(),
            '': (
              <Access permission='users_EDIT'>
                <button
                  className='btn-sm btn btn-outline-primary'
                  onClick={() => {
                    setUser(data);
                    showEditModal(true);
                  }}>
                  Edit
                </button>
              </Access>
            ),
          })}></Table>
      </div>

      <Modal isActive={createModal} close={showCreateModal} title={'Add User '}>
        <Form
          onSubmit={createUser}
          onChange={(data) => {
            setInputs({ ...data, status: 1 });
          }}
          submit={'Submit'}
          inputs={[
            {
              label: 'first name',
              key: 'firstname',
              required: true,
              autocomplete: 'off',
            },
            {
              label: 'last name',
              key: 'lastname',
              required: true,
              autocomplete: 'off',
            },

            {
              label: 'email',
              key: 'email',
              type: 'email',
              required: true,
              autocomplete: 'off',
            },

            {
              label: 'User Type',
              key: 'type',
              type: 'select',
              required: true,
              autocomplete: 'off',
              options: userTypes,
            },
            {
              label: 'password',
              key: 'password',
              type: 'password',
              required: true,
              autocomplete: 'off',
            },
          ]}
        />
      </Modal>

      <Modal isActive={editModal} close={showEditModal} title={'Edit User '}>
        {showEditModal && (
          <Form
            onSubmit={updateUser}
            onChange={(data) => {
              setUser({ ...user, ...data });
            }}
            submit={'Submit'}
            inputs={[
              {
                label: 'first name',
                key: 'firstname',
                required: true,
                autocomplete: 'off',
                initialValue: user.firstname,
              },
              {
                label: 'last name',
                key: 'lastname',
                required: true,
                autocomplete: 'off',
                initialValue: user.lastname,
              },

              {
                label: 'email',
                key: 'email',
                type: 'email',
                required: true,
                autocomplete: 'off',
                initialValue: user.email,
              },

              {
                label: 'User Type',
                key: 'type',
                type: 'select',
                required: true,
                autocomplete: 'off',
                initialValue: user.type,
                options: userTypes,
              },
              {
                label: 'password',
                key: 'password',
                type: 'password',
                required: true,
                autocomplete: 'off',
              },
            ]}
          />
        )}
      </Modal>
    </div>
  );
}

export default Users;
