import React, { useState } from 'react';
import {
  BoxArrowRight,
  ChevronDown,
  ChevronUp,
  Gear,
} from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';

import user from '../../img/user.svg';
import Access from './accessManager';

function Menu({ menu, navLogo }) {
  let [active, setActive] = useState();

  return (
    <div className='side-menu border-end'>
      {/* {navLogo && (
        <div className='nav-logo my-4 px-3'>
          <img src={navLogo} alt='' />
        </div>
      )} */}

      {window.user && (
        <div>
          <div className='d-flex flex-column user-login-card p-3 align-items-start'>
            <div className='d-flex flex-row align-items-start justify-content-between w-100'>
              <div className='user-icon-parent'>
                <img src={user} className='user-icon' alt='' />
              </div>
              <div className='d-flex flex-row'>
                <div className='action-icon' title='Settings'>
                  <Gear size={20}></Gear>
                </div>

                <div
                  className='action-icon ms-2'
                  title='Logout'
                  onClick={() => window.logout()}>
                  <BoxArrowRight size={20}></BoxArrowRight>
                </div>
              </div>
            </div>
            <div className='mt-3 text-capitalize'>
              {window.user.firstname} {window.user.lastname}
            </div>
            {/* <div>
              <small className='font-weight-bold'>|</small>
            </div> */}
          </div>
        </div>
      )}

      <div className='side-nav'>
        {menu.map((d, i) => {
          return (
            <Access permission={d.permission}>
              <NavLink
                className={({ isActive }) => {
                  return (
                    'nav-link ' +
                    (isActive && 'active ') +
                    (d.children && ' has-children')
                  );
                }}
                to={d.link}
                key={i}
                onClick={(e) => {
                  setActive(d.link);
                  if (d.children) e.preventDefault();
                }}>
                <div className='link-content'>
                  <div className='me-3'>{d.icon}</div> <div> {d.name}</div>
                  {d.children && (
                    <div className='ms-auto'>
                      {active !== d.link && (
                        <ChevronDown size={18}></ChevronDown>
                      )}
                      {active === d.link && <ChevronUp size={18}></ChevronUp>}
                    </div>
                  )}
                </div>

                {active === d.link && d.children && (
                  <div className='collapse show shadow-sm'>
                    <div className=' py-2 collapse-inner rounded mb-0'>
                      {/* <h6 className='collapse-header'>listing:</h6> */}
                      {d.children.map((d1, i1) => {
                        return (
                          <NavLink
                            className={({ isActive }) => {
                              return (
                                'nav-link collapse-item font-weight-bold my-1 text-capitalize ' +
                                (isActive && 'active')
                              );
                            }}
                            to={d1.link}
                            key={i1}>
                            {d1.name}
                          </NavLink>
                          // </Access>
                        );
                      })}
                    </div>
                  </div>
                )}
              </NavLink>
            </Access>
          );
        })}
      </div>
    </div>
  );
}

export default Menu;
