import React, { Component, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from '../components/card';
import Nav from '../components/system/nav';
import start from '../img/work.svg';
import logo from '../img/logo_black.svg';
import { useState } from 'react';
import { UiChecks } from 'react-bootstrap-icons';

function Home() {
  let [forms, setForms] = useState([]);

  let navigate = useNavigate();

  let fetchForms = () => {
    window.app
      .service('lead-forms')
      .find({ $limit: 100 })
      .then(async (res) => {
        setForms(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <div className='px-md-4'>
      <div className='container'>
        <div className=' py-4'>
          <div className='intro-parent'>
            <div className='col-md-6'>
              {/* <h2>Leads System</h2> */}
              <div className='mt-2 title text-primary'>
                |{'  '} {/* <small> */}
                Tandem Network
                {/* </small> */}
              </div>
            </div>
          </div>
        </div>
        <div className='card py-4 px-5 mt-2 intro-card my-5'>
          <div className='row'>
            <div className='col-md-8'>
              <h4 className='font-weight-bold intro-title fw-bold'>
                Welcome Back !
              </h4>
              <div className='mt-3'>
                To get started, use the menu on your left. All the general
                summaries will be displayed on this page. Any new system updates
                will be displayed here.
              </div>
            </div>
            <div className='col-md-4 flex-row justify-content-center d-md-flex d-none'>
              <img src={start} alt='' className='intro-img' />
            </div>
          </div>
        </div>

        <div className='mt-2 text-primary fw-bold title'>
          |{'  '} {/* <small> */}
          Liberty Insurance Products
          {/* </small> */}
        </div>
        <div className='row mt-4'>
          <div className='col-md-3'>
            <Link
              to={`forms`}
              className=' p-3 btn btn-primary text-start w-100'>
              <UiChecks size={40}></UiChecks>

              <h5 className='my-4'>All Products</h5>

              <div className='d-flex'></div>

              <small>liberty.co.ke</small>
            </Link>
          </div>
          {Object.keys(window.products).map(
            (d, i) =>
              !i && (
                <div className='col-md-3 mb-4' key={i}>
                  <Link
                    to={`forms?product=${d}`}
                    className=' p-3 btn btn-primary text-start w-100'>
                    <UiChecks size={40}></UiChecks>

                    <h5 className='my-4'>{window.products[d]}</h5>

                    <div className='d-flex'></div>

                    <small>liberty.co.ke</small>
                  </Link>
                </div>
              )
          )}
          {/* {forms.map((d, i) => (
            <div className='col-md-3' key={i}>
              <Link
                to={`forms/${d.id}/${d.name}`}
                className=' p-3 btn btn-primary text-start w-100'>
                <UiChecks size={40}></UiChecks>

                <h5 className='my-4'>{d.name}</h5>

                <div className='d-flex'></div>

                <small>liberty.co.ke</small>
              </Link>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

export default Home;
