import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/style.scss';
import Dashboard from './dashboard';
import Login from './dashboard/login';

window.products = {
  2: 'Money Master',
  105: 'LifeVest Plan',
  32: 'Boresha Maisha Individual Pension Plan',
  49: 'Boresha Ustaafu Income Draw Down',
  48: 'Boresha Maisha Umbrella Plan',
  67: 'Soma Savings Plan',
  47: 'Family Protector',
  46: 'Group Funeral Cover',
  43: 'Group Life Cover',
  39: 'Triple Diamond Plan',
  44: 'Group Credit Life/Mortgage Protection',
  62: 'Hekima Plan',
  37: 'Income Builder',
  34: 'Scholar Plan',
  41: 'Legacy Plan',
  35: 'Educator Plan',
  33: 'Group Annuity',
  31: 'Investment Products',
};

window.lead_status = {
  1: 'Active',
  2: 'Reviewed',
  0: 'Disabled',
  3: 'Did Not Pick',
  4: 'To Call Back',
  // 5:"Unresponsive",
  // 6:"No Car",
  7: 'Uninterested',
  8: 'Interested but went to Competition',
  9: 'Interest in Other insurance',
  10: 'Interested',
  11: 'Interested in other products',
  12: 'Closed',
  13: 'To visit',
};

function App() {
  return (
    <div>
      {/* initialize dashboard editor route */}
      {/* <Router basename='god_mode'>
        <DasboardEditor></DasboardEditor>
      </Router> */}
      <Router basename='usr'>
        <Routes>
          <Route exact path='/login' element={<Login />} />
        </Routes>
      </Router>
      <Router>
        <Dashboard></Dashboard>
      </Router>
    </div>
  );
}

export default App;
