import React, { useEffect, useState } from 'react';
import Form from '../components/system/form';

function Settings() {
  let [user, setUser] = useState({});
  let [loading, setLoading] = useState(false);
  let [spamKeywords, setSpamKeywords] = useState([]);
  let [newKeyword, setNewKeyword] = useState('');

  useEffect(() => {
    // fetch featured blog
    fetchUser();
  }, []);

  let fetchUser = async () => {
    window.app
      .service('users')
      .get(window.user.id)
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let updateProfile = async (data) => {
    // console.log(data);
    let response = await window.app
      .service('users')
      .patch(window.user.id, data);
    // console.log(response);
    window.notify('Profile Updated', 'success');
    // fetchUser();

    // refresh page
    window.location.reload();
  };

  let updateSpamKeywords = async () => {
    spamKeywords.map(async (keyword) => {
      let response = await window.app
        .service('spam-keywords')
        .patch(keyword.id, keyword);
    });

    window.notify('Spam Keywords Updated', 'success');
    fetchSpamKeywords();
  };

  let fetchSpamKeywords = async () => {
    let response = await window.app.service('spam-keywords').find({
      query: {
        $limit: 100,
      },
    });
    setSpamKeywords(response.data);
  };

  let createNewKeyword = async () => {
    let response = await window.app.service('spam-keywords').create({
      keyword: newKeyword,
      values: [],
    });
    setNewKeyword('');
    fetchSpamKeywords();
  };

  return (
    <div className='pt-4'>
      <h4 className='ps-3 fw-bold'>General Settings</h4>

      {/* General settings is where the user can update name profile photo or get email link to reset password */}

      <div className='card pt-4 px-3  mx-3 shadow-sm '>
        <div className='row'>
          <div className='col-md-4'>
            <Form
              className='row g-3'
              onSubmit={(data) => {
                console.log(data);
                setLoading(true);
                updateProfile(data);
              }}
              submit={'Save'}
              inputs={[
                {
                  label: 'First Name',
                  key: 'firstname',
                  type: 'text',
                  placeholder: 'First Name',
                  required: true,
                  initialValue: user.firstname,
                },
                {
                  label: 'Last Name',
                  key: 'lastname',
                  type: 'text',
                  placeholder: 'Last Name',
                  required: true,
                  initialValue: user.lastname,
                },
                {
                  label: 'Email',
                  key: 'email',
                  type: 'email',
                  placeholder: 'Email',
                  required: true,
                  disabled: true,
                  initialValue: user.email,
                },
                // {
                //   label: 'Profile Photo',
                //   name: 'profile_photo',
                //   type: 'file',
                //   placeholder: 'Profile Photo',
                //   required: false,
                //   defaultValue: user.profile_photo,
                // },
              ]}
            />
          </div>
        </div>
      </div>

      {/* Set keywords for spam in the lead columns */}
      <h4 className='ps-3 fw-bold mt-4'>Spam Settings</h4>
      <div className='card pt-4 px-3  mx-3 shadow-sm '>
        <div className='row'>
          <div className='col-md-4'>
            <Form
              className='row g-3'
              onSubmit={(data) => {
                setLoading(true);
                updateSpamKeywords(data);
              }}
              inputs={spamKeywords.map((keyword, i) => {
                return {
                  label: keyword.name,
                  name: 'keyword',
                  type: 'creatable-select',
                  defaultValue: keyword.values.map((value) => {
                    return { label: value, value: value };
                  }),
                  placeholder: 'Keyword',
                  required: false,
                  onChange: (data) => {
                    let $spamKeywords = [...spamKeywords];
                    $spamKeywords[i].values = data.map((item) => item.value);
                    setSpamKeywords($spamKeywords);
                  },
                };
              })}
            />

            {/* add keywords */}
            <div className='col-md-12'>
              <div className='form-group custom-input mb-3'>
                <label className='label'>Add Keyword</label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Keyword'
                    value={newKeyword}
                    onChange={(e) => {
                      let value = e.target.value;
                      setNewKeyword(value);
                    }}
                  />
                  <button
                    className='btn btn-primary '
                    onClick={() => {
                      createNewKeyword();
                    }}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
