import { upload } from '@testing-library/user-event/dist/upload';
import React, { useEffect, useState } from 'react';
import { Cloud, CloudUpload, Upload } from 'react-bootstrap-icons';
// import ReactGoogleAutocomplete from 'react-google-autocomplete';
import CreatableSelect from 'react-select/creatable';
// import PlacesInput from './placesInput';

function Input({
  placeholder,
  type,
  className,
  label,
  description,
  onChange,
  required,
  initialValue,
  disabled,
  style,
  size,
  autocomplete,
  options,
  disableResize,
  val,
}) {
  let [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(val);
  }, [val]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  let uploadFile = async (e) => {
    // show react-toastify notification and make it an infinite notification
    let toastId = window.notify('Uploading ' + e.target.files[0].name, 'info', {
      autoClose: false,
    });
    // get file from event

    let file = e.target.files[0];
    // upload to feathers upload service using fetch
    let formData = new FormData();
    formData.append('uri', file);
    let response = await fetch(
      `${window.location.origin}/upload?uri=${file.name}`,
      {
        method: 'POST',
        body: formData,
      }
    );
    let data = await response.json();

    // remove react-toastify notification
    window.notify('Uploaded ' + e.target.files[0].name, 'success', toastId);

    // console.log(data);
    return data.uri;
  };

  return (
    <div
      class={
        'form-group custom-input mb-3  ' +
        ((!disableResize && size) || 'col-md-12')
      }>
      {label && <label className='label'>{label}</label>}
      {type === 'select' ? (
        <select
          className='form-control'
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            setValue(e.target.value);
          }}
          defaultValue={initialValue}>
          <option value='' default selected hidden>
            Select
          </option>
          {options &&
            options.map((d, i) => (
              <option value={d.value} key={i}>
                {d.label}
              </option>
            ))}
        </select>
      ) : type === 'textarea' ? (
        <textarea
          rows='4'
          type={type}
          className={'form-control ' + className}
          placeholder={placeholder || '...'}
          required={required}
          value={value}
          disabled={disabled}
          style={style}
          onChange={(e) => {
            onChange(e.target.value);
            setValue(e.target.value);
          }}></textarea>
      ) : type === 'react-select' ? (
        <CreatableSelect
          options={options}
          onChange={({ value }) => {
            console.log('value', value);
            onChange(value);
            setValue(value);
          }}
          menuShouldScrollIntoView={true}
          defaultValue={initialValue}
        />
      ) : type === 'maps-autocomplete' ? (
        // <ReactGoogleAutocomplete
        //   apiKey={'AIzaSyBNQrBGpslZSi89D69eAOglXpMynI0WT7c'}
        //   onPlaceSelected={(place) => {
        //     onChange(
        //       {
        //         location: place.formatted_address,
        //         lat: place.geometry.location.lat(),
        //         lng: place.geometry.location.lng(),
        //       },
        //       true
        //     );

        //     console.log();
        //   }}
        //   defaultValue={initialValue}
        //   className='form-control'
        //   placeholder={placeholder}
        //   options={{
        //     types: [],
        //     componentRestrictions: { country: 'ke' },
        //   }}
        // />
        <></>
      ) : // file type
      type === 'file' ? (
        <input
          type={type}
          className={'form-control ' + className}
          placeholder={placeholder || '...'}
          required={required}
          value={value}
          style={style}
          onChange={(e) => {
            let filename = uploadFile(e.target.files[0]);
            onChange(filename);
            setValue(filename);
          }}
          disabled={disabled}
          autocomplete={autocomplete}
        />
      ) : // image type
      type === 'image' ? (
        <label className='custom-file-upload'>
          <input
            type='file'
            className={'d-none ' + className}
            placeholder={placeholder || '...'}
            required={required}
            value={value}
            style={style}
            onChange={async (e) => {
              let filename = await uploadFile(e.target.files[0]);
              onChange(filename);
              setValue(filename);
            }}
            disabled={disabled}
            autocomplete={autocomplete}
          />
          {/* bs5 icon */}
          <CloudUpload className='mr-2' />
          {value ? (
            <img src={value} alt='...' className='img-fluid' />
          ) : (
            <span>Upload Image</span>
          )}
        </label>
      ) : (
        // <PlacesInput></PlacesInput>
        <input
          type={type}
          className={'form-control ' + className}
          placeholder={placeholder || '...'}
          required={required}
          value={value}
          style={style}
          onChange={(e) => {
            onChange(e.target.value);
            setValue(e.target.value);
          }}
          disabled={disabled}
          autocomplete={autocomplete}
        />
      )}
      {description && (
        <small class='form-text text-muted description'>{description}</small>
      )}
    </div>
  );
}

export default Input;
