import React from 'react';
import { useEffect, useState } from 'react';
import Modal from './system/modal';
import Input from './system/input';

// modal to assign lead ownership. fetches all users in database and displays them in a bootstrap list group with radio inputs. It uses fuzzy search to search for users by first name, last name and email.

function AssignLeadOwnership(props) {
  let [users, setUsers] = useState([]);
  let [filteredUsers, setFilteredUsers] = useState([]);
  let [search, setSearch] = useState('');
  let [selected, setSelected] = useState(props.userId);
  let [showAssignOwnershipModal, setShowAssignOwnershipModal] = useState(
    props.isActive
  );

  let fetchUsers = async () => {
    let users = await window.app.service('users').find({
      query: {
        $limit: 250,
        $skip: 0,
      },
    });
    setUsers(users.data);
  };

  useEffect(() => {
    setFilteredUsers(
      users.filter((d) => {
        let searchRegex = new RegExp(search, 'i');
        return (
          searchRegex.test(d.firstname) ||
          searchRegex.test(d.lastname) ||
          searchRegex.test(d.email)
        );
      })
    );
  }, [search, users]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setShowAssignOwnershipModal(props.isActive);
  }, [props.isActive]);

  return (
    <Modal
      isActive={showAssignOwnershipModal}
      close={props.close}
      title='Assign Lead Ownership'
      className={'assign-lead-ownership-modal'}>
      <div className='row'>
        <div className='col-md-12'>
          <Input placeholder='Search' onChange={setSearch} />
        </div>
      </div>
      <div className='row assign-lead-ownership-modal-list'>
        <div className='col-md-12'>
          <ul className='list-group'>
            {filteredUsers.map((d, i) => (
              <li
                className={
                  'list-group-item list-group-item-action ' +
                  (selected == d.id ? 'active' : '')
                }>
                <label className='row'>
                  <div className='col-md-1'>
                    <input
                      type='radio'
                      name='user'
                      value={d.id}
                      onChange={(e) => setSelected(e.target.value)}
                    />
                  </div>
                  <div className='col-md-11'>
                    <div className='row'>
                      <div className='col-md-5'>
                        {d.firstname} {d.lastname}
                      </div>
                      <div className='col-md-7 text-end '>
                        <small> {d.email}</small>
                      </div>
                    </div>
                  </div>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 text-center mt-4'>
          <button
            className='btn btn-primary px-5'
            disabled={!selected}
            onClick={() => {
              if (selected && props.onSubmit) props.onSubmit(selected);

              props.close(false);
            }}>
            Assign to{' '}
            {selected ? users.find((d) => d.id == selected).firstname : ''}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AssignLeadOwnership;
