import React, { useState } from 'react';
import Input from '../components/system/input';
import Modal from '../components/system/modal';
import Nav from '../components/system/nav';
import Table from '../components/system/table';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from '../components/system/accessManager';

function Forms() {
  let [data, setData] = useState();
  let [activeProduct, setActiveProduct] = useState();
  let [initializeTable, setInitializeTable] = useState(false);
  let [options, setOptions] = useState([]);

  let [query, setQuery] = useState({
    $sort: { submitted: -1 },
    $limit: 10,
  });

  let products = window.products;

  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    fetchItems();
  }, [query]);

  let fetchItems = async () => {
    // console.log('query', {
    //   leadFormId: params.id,
    //   $sort: { id: 1 },
    //   ...query,
    // });

    let $query = { ...query };
    if ($query.submitted && ($query.submitted.$gte || $query.submitted.$lte)) {
      $query.submitted = {
        $gte: moment($query.submitted.$gte).unix(),
        $lte: moment($query.submitted.$lte).unix(),
      };
    }
    if (isNaN($query.status)) {
      $query.status = {
        $gt: 0,
      };
    }

    if (isNaN($query['data.product_of_interest'])) {
      delete $query['data.product_of_interest'];
    }

    console.log($query);

    return await window.app
      .service('leads')
      .find({
        query: {
          leadFormId: params.id,
          $sort: { id: 1 },
          ...$query,
        },
      })
      .then((response) => {
        // console.log('response', response);
        setData(response);
      })
      .catch((err) => {
        // this.setState({ table_loading: false });
        console.error(err);
      });

    // console.log(filter);
  };

  let exportCSV = async () => {
    let $query = { ...query };
    if ($query.submitted && ($query.submitted.$gte || $query.submitted.$lte)) {
      $query.submitted = {
        $gte: moment($query.submitted.$gte).unix(),
        $lte: moment($query.submitted.$lte).unix(),
      };
    }
    if (isNaN($query.status)) {
      $query.status = {
        $ne: 0,
      };
    }

    if (isNaN($query['data.product_of_interest'])) {
      delete $query['data.product_of_interest'];
    }
    console.log($query);

    if (!window.confirm('Are You sure that you want to export This data?'))
      return;

    window.app
      .service('export-form')
      .create(
        {},
        {
          query: {
            leadFormId: params.id,
            ...$query,
          },
        }
      )
      .then((response) => {
        window.toast.success('Export complete', { theme: 'dark' });

        window.open(window.server + '/download/' + response.filename, '_blank');

        // return response;
      })
      .catch((err) => {
        setData(data);
        console.error(err);
      });
  };

  useEffect(() => {
    // filter by product url parameter using '?product=' if exists
    let urlParams = new URLSearchParams(window.location.search);
    let product = urlParams.get('product');
    if (product) {
      setQuery({
        ...query,
        'data.product_of_interest': parseInt(product),
      });

      setActiveProduct(parseInt(product));
    }

    // fetch options
    setOptions(
      Object.keys(window.lead_status).map((d) => ({
        key: window.lead_status[d],
        value: d,
      }))
    );

    setInitializeTable(true);
  }, []);

  let updateUser = (id, status) => {
    window.app
      .service('leads')
      .patch(id, { status, action: 'status_update' })
      .then((response) => {
        fetchItems();
        window.toast.success('Status updated successfully');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className=''>
      <Nav
        title={params.name}
        links={[
          {
            text: 'Export',
            onClick: () => {
              exportCSV();
            },
          },
        ]}></Nav>

      <div className='card page-card p-3 mx-3 shadow-sm'>
        {initializeTable ? (
          <Table
            sort={'createdAt'}
            sortDirection={-1}
            sortable={['submitted', 'status', 'updatedAt']}
            // search={['kpi']}
            calendar={'submitted'}
            data={data}
            columns={[
              {
                key: '#',
                label: 'id',
              },
              // {
              //   key: 'full_name',
              //   label: 'Full Name',
              //   span: 2,
              //   dynamic: true,
              // },
              {
                key: 'first_name',
                label: 'First Name',
                // dynamic: true,
              },
              {
                key: 'last_name',
                label: 'Last Name',
                // dynamic: true,
              },
              {
                key: 'goal',
                label: 'Goal',
              },
              {
                key: 'email',
                label: 'Email',
              },
              {
                key: 'contact_number',
                label: 'Phone',
              },
              {
                key: 'product_of_interest',
                label: 'Product',
              },
              {
                key: 'status',
                label: 'Status',
              },
              {
                key: 'submitted',
                label: 'Submitted',
              },
              {
                key: 'message',
                label: 'Message',
              },

              // {
              //   key: 'updatedAt',
              //   label: 'Updated',
              // },
            ]}
            filter={[
              {
                label: 'Status',
                filterKey: 'status',
                options,
              },
              {
                label: 'Product',
                filterKey: 'data.product_of_interest',
                options: Object.keys(products).map((d) => ({
                  key: products[d],
                  value: d,
                })),
                defaultValue: activeProduct,
              },
            ]}
            fetch={(q) => {
              setQuery({ ...query, ...q });
            }}
            entry={(data) => {
              let output = {
                '#': data.id,
                ...data.data,
                product_of_interest: products[data.data.product_of_interest],
                status: (
                  <div>
                    <select
                      className='select-sm'
                      id=''
                      disabled={
                        hasPermission('leads_UPDATE_STATUS') ? false : true
                      }
                      defaultValue={data.status}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        e.stopPropagation();
                        updateUser(data.id, e.target.value);
                      }}
                      style={{
                        maxWidth: 120,
                      }}>
                      {options.map((d) => (
                        <option value={d.value}>{d.key}</option>
                      ))}
                    </select>
                  </div>
                ),
                submitted: moment
                  .unix(data.submitted)
                  .format('DD/MM/YYYY  hh:mm'),
                updatedAt: moment(data.updatedAt).format('DD/MM/YYYY  hh:mm'),
                onClick: () => {
                  // window.location.href = '/lead-profile/' + data.id;
                  navigate('/lead-profile/' + data.id);
                },
              };

              if (output.full_name) {
                output.first_name = output.full_name.split(' ')[0];
                output.last_name = output.full_name.split(' ')[1];
              }

              if (output.phone_number) {
                output.contact_number = output.phone_number;
              }

              return output;
            }}></Table>
        ) : (
          <div className='text-center'>
            <span>Loading ...</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Forms;
