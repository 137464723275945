import React, { Suspense, useEffect, useState } from 'react';
import {
  Gear,
  HouseDoor,
  People,
  PersonCheck,
  Shield,
} from 'react-bootstrap-icons';
import { Route, Routes } from 'react-router-dom';
import Layout from '../layout';
import Home from './home';

// import feathers from '@feathersjs/client';
import Alert from '../components/alert';
import Users from './users';
import Settings from './settings';
import Loader from '../components/system/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Forms from './form';
import Lottie from 'react-lottie';
import logout from '../img/lottie/logout.json';
import LeadsProfile from './leadsProfile';
import AccessManagement from './accessManagement';
import Access from '../components/system/accessManager';

// const rest = require('@feathersjs/rest-client');

const feathers = require('@feathersjs/feathers');
const rest = require('@feathersjs/rest-client');
const auth = require('@feathersjs/authentication-client');
// const io = require('socket.io-client');

window.toast = toast;
window.notify = (message, type, id) => {
  if (id) {
    toast.update(id, {
      render: message,
      type: type,
    });
    return id;
  } else {
    return toast(message, {
      type: type,
    });
  }
};

window.logout = (force) => {
  if (force) {
    window.app.logout();
    window.location = '/login';
    localStorage.clear();
    return false;
  }

  window.Alert.confirm({
    icon: (
      <div
        style={{
          width: 189,
        }}>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: logout,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={189}
          width={189}
        />
      </div>
    ),
    title: 'Are you sure that you want to Logout ?',
    message: 'If you agree your session will be terminated',
    confirmText: 'Log Out',
    onSubmit: () => {
      window.app.logout();
      window.location = '/login';
      localStorage.clear();
    },
  });
};

let menu = [
  {
    name: 'Dashboard',
    icon: <HouseDoor size={20} />,
    link: '/',
    permission: 'all',
  },
  {
    name: 'Users',
    icon: <People size={20} />,
    link: '/users',
    permission: 'users_READ',
  },
  {
    name: 'Leads',
    icon: <PersonCheck size={20} />,
    link: '/forms',
    permission: 'leads_READ',
  },
  {
    name: 'Settings',
    icon: <Gear size={20} />,
    link: '/settings',
    permission: 'all',
  },
  {
    name: 'Access Management',
    icon: <Shield size={20} />,
    link: '/access-management',
    permission: 'access_management_READ',
  },
];

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  window.server = 'http://localhost:3030';
  window.fileserver = 'http://localhost:3030/downloads/';
  window.stagingServer = 'http://localhost:8000';
} else {
  // production code
  window.server = 'https://leads.tandem.network/api';
  window.fileserver = 'https://leads.tandem.network/downloads/';
}

// window.server = 'https://leads.tandem.network/api';
// window.fileserver = 'https://leads.tandem.network/downloads/';

// const socket = io(window.server, { path: '/api/socket.io' });

// configure rest api

window.app = feathers();
const restClient = rest(window.server);
window.app.configure(restClient.fetch(window.fetch.bind(window)));

window.app.configure(
  auth({
    storage: window.localStorage,
  })
);

function Dashboard() {
  let [authorized, setAuthorized] = useState(false);
  let [loading, setLoading] = useState(true);

  let authenticate = () => {
    return window.app
      .reAuthenticate()
      .then(async (response) => {
        // window._app = feathers();
        // const restClient = rest(window.server);
        // window._app.configure(
        //   restClient.fetch(window.fetch, {
        //     Authorization: 'Bearer ' + response.accessToken,
        //   })
        // );

        window.accessToken = response.accessToken;

        localStorage.user = JSON.stringify(response.user);
        window.user = response.user;

        console.log('authorized');

        await fetchDefaults();

        setAuthorized(true);
        setLoading(false);

        let path = window.location.pathname;

        // console.log('path before ', path);

        // if (path.includes('authenticate') || path.includes('get-started'))
        //   return false;
        // console.log('path after ', path);
      })
      .catch((error) => {
        console.log('::NOT AUTHORIZED', error);

        setLoading(false);
        setAuthorized(false);

        if (!window.location.pathname.includes('/usr')) {
          window.location = '/usr/login';
        }
      });
  };

  let fetchDefaults = async () => {
    await window.app
      .service('permissions')
      .find({
        query: {
          $limit: 250,
          $skip: 0,
          userGroupId: window.user.type,
        },
      })
      .then((response) => {
        window.permissions = {};

        let data = [];
        response.data.map((d) => {
          window.permissions[
            d.permission_type.category.replace(/-/g, '_') +
              '_' +
              d.permission_type.name
          ] = d;
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    authenticate();
  }, []);

  return (
    <>
      <Loader loading={loading} className='floating-loader'></Loader>

      <Alert
        onRef={(ref) => {
          window.alert2 = ref;
          window.Alert = ref;
        }}></Alert>

      <ToastContainer />
      <div className='page-blur '>
        {authorized && (
          <Layout menu={menu}>
            <Routes>
              <Route exact path='/' element={<Home />} />

              <Route
                exact
                path='/users'
                element={
                  <Access page={true} permission='users_READ'>
                    <Users />
                  </Access>
                }
              />
              <Route exact path='/settings' element={<Settings />} />
              <Route
                exact
                path='/forms'
                element={
                  <Access page={true} permission='leads_READ'>
                    <Forms />
                  </Access>
                }
              />
              <Route
                exact
                path='/lead-profile/:id'
                element={
                  <Access page={true} permission='leads_READ'>
                    <LeadsProfile />
                  </Access>
                }
              />
              <Route
                exact
                path='/access-management'
                element={
                  <Access page={true} permission='access_management_READ'>
                    <AccessManagement />
                  </Access>
                }
              />
            </Routes>
          </Layout>
        )}

        {loading && <div className='page-loader'></div>}
      </div>
    </>
  );
}

export default Dashboard;
