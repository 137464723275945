import React, { Component } from 'react';
import logo from '../img/logo_white.svg';
// import { User, Lock, Mail, Eye, EyeOff, XCircle } from 'react-feather';
// import { Link } from 'react-router-dom';
import { Envelope, Eye, EyeSlash, Lock, XCircle } from 'react-bootstrap-icons';
import LButton from '../components/loadingButton';
import Modal from '../components/system/modal';

class Login extends Component {
  state = {
    custom: true,
    loading: false,
    email: '',
    password: '',
    resetVisible: false,
    resetStatus: 0,
    loginStatus: 0,
    showPassword: false,
  };
  render() {
    return (
      <div className='d-flex flex-fill flex-column main-cover justify-content-center wallpaper'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10 col-lg-12 col-md-9'>
              <div className='card o-hidden border-0 shadow-lg my-5 login-card'>
                <div className='card-body p-0'>
                  <div className='row'>
                    <div className='col-lg-6 bg-primary d-none default-bg align-items-center justify-content-center flex-row d-md-flex icon-holder'>
                      <img src={logo} className='main-logo' alt='' />
                    </div>
                    <div className='col-lg-6'>
                      <div className='p-md-5 p-4'>
                        <div className='text-center'>
                          <h1 className='h4 text-gray-900 mb-4 font-weight-bold'>
                            <div>Leads System</div>
                          </h1>
                        </div>

                        <div className='text-center mb-4'>
                          <small className='text-muted'>Admin login</small>
                        </div>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.login();
                          }}>
                          <div className='form-group d-flex flex-row align-items-center mb-4'>
                            <Envelope
                              className='login-icon align-self-center position-absolute ms-2'
                              color='gray'
                              size={18}
                            />

                            <input
                              type='email'
                              className='form-control form-control-user  icon-input'
                              placeholder='Email Address'
                              value={this.state.email}
                              required={true}
                              onChange={(e) => {
                                this.setState({ email: e.target.value });
                              }}
                            />
                          </div>
                          <div className='form-group d-flex flex-row align-items-center position-relative mb-4'>
                            <Lock
                              className='login-icon align-self-center position-absolute ms-2 '
                              color='gray'
                              size={18}></Lock>
                            <input
                              type={
                                this.state.showPassword ? 'text' : 'password'
                              }
                              required={true}
                              className='form-control form-control-user icon-input'
                              placeholder='Password'
                              autocomplete='off'
                              onChange={(e) => {
                                this.setState({ password: e.target.value });
                              }}
                              value={this.state.password}
                            />

                            {!this.state.showPassword && (
                              <Eye
                                size={18}
                                className='login-icon align-self-center position-absolute eye-icon'
                                onClick={() =>
                                  this.setState({
                                    showPassword: !this.state.showPassword,
                                  })
                                }></Eye>
                            )}

                            {this.state.showPassword && (
                              <EyeSlash
                                size={18}
                                className='login-icon align-self-center position-absolute eye-icon'
                                onClick={() =>
                                  this.setState({
                                    showPassword: !this.state.showPassword,
                                  })
                                }></EyeSlash>
                            )}
                          </div>
                          <div className='form-group d-flex flex-row align-items-center justify-content-between position-relative'>
                            <div
                              className=''
                              onClick={() =>
                                this.setState({ resetVisible: true })
                              }>
                              <small>Forgot password?</small>
                            </div>
                          </div>
                          <div className='text-center'>
                            <div className='d-inline-block'>
                              <LButton
                                text='Login'
                                status={this.state.loginStatus}></LButton>
                            </div>
                          </div>
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isVisible={this.state.resetVisible}
          close={() => {
            this.setState({ resetVisible: false });
          }}>
          <div className='py-3'>
            <h5 className='text-center font-weight-bold'>Reset password</h5>
          </div>

          <div className='form-group d-flex flex-row align-items-center mx-3'>
            <Envelope
              className='login-icon align-self-center position-absolute ms-2'
              color='gray'
              size={18}
            />

            <input
              type='email'
              className='form-control form-control-user ps-4 icon-input'
              placeholder='Email Address'
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
          </div>
          <div className='text-center'>
            <div className='my-3 d-inline-block'>
              <LButton
                text='Reset'
                status={this.state.resetStatus}
                onClick={() => this.resetEmail()}></LButton>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  login = async () => {
    // If that errors, log in with email/password
    // Here we would normally show a login page
    // to get the login information
    this.setState({ loginStatus: 1 });
    let { email, password } = this.state;

    window.app
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then((response) => {
        // console.log(response);

        // if (!response.user.status) {
        //   window.alert2.show({
        //     loader: false,
        //     icon: <Envelope size={60} className='my-3'></Envelope>,
        //     title: 'Please Verify Your Email address.',
        //     message: (
        //       <span>
        //         {' '}
        //         Check Your inbox for a verification email from Tandem.
        //         <b> Please verify your Email</b> in Order to proceed{' '}
        //       </span>
        //     ),
        //     confirmText: 'Ok',
        //     buttons: true,
        //   });

        //   localStorage.clear();
        //   this.setState({ loginStatus: 0 });

        //   return false;
        // }
        this.setState({ loginStatus: 2 });
        localStorage.user = JSON.stringify(response.user);
        window.location = '/';
      })
      .catch((err) => {
        this.setState({ loginStatus: 0 });
        console.error(err);
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className=''></XCircle>,
          title: 'Login Error!',
          message: 'Wrong Email/password comibination',
          buttons: true,
        });
      });
  };

  resetEmail = () => {
    let { email } = this.state;
    if (!(email !== '')) {
      alert('Please enter a valid email');
      return false;
    }

    this.setState({ resetStatus: 1 });

    fetch(window.server + '/utils/resetpassword', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: `{"email": "${email}"}`,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.code) {
          this.setState({ resetStatus: 0 });

          alert(response.message);
        } else {
          this.setState({ resetStatus: 2 });
          alert(response.result);
        }
      })
      .catch((e) => {
        this.setState({ resetStatus: 0 });
        console.error(e);
      });
  };
}

export default Login;
